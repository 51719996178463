import axios from '@/libs/axios'

const Ap2i = () => {
  const fetchData = (module, queryParams) => new Promise((resolve, reject) => {
    axios
      .get(`/${module}`, { params: queryParams })
      .then(response => {
        resolve(response.data)
      })
      .catch(error => reject(error))
  })

  return { fetchData }
}

class Api {
  static fetch(module, queryParams) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/${module}`, { params: queryParams })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }

  static get(module, id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/${module}/${id}`)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }

  static post(module, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/${module}`, payload)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }

  static update(module, id, payload) {
    return new Promise((resolve, reject) => {
      axios
        .patch(`/${module}/${id}`, payload, {
          headers: { 'Content-Type': 'application/merge-patch+json' },
        })
        .then(response => {
          resolve(response.data)
        })
        .catch(error => reject(error))
    })
  }
}

export default Api
